import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Table`}</h1>
    <p>{`Tables organize and display information
from a data set into rows and columns.`}</p>
    <ComponentPreview componentName="table--column-comparison" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Table Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Table for data that is intended to be
easily scanned and compared. If your data is
only key value pairs, consider the
`}<a parentName="p" {...{
        "href": "/using-spark/components/dictionary"
      }}>{`Dictionary`}</a>{`
component instead.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`If the contents of a Table would cause it to exceed
the width of the viewport, the Table will become
horizontally scrollable.`}</li>
      <li parentName="ul">{`The data in a Table column should be related to
the column heading.`}</li>
      <li parentName="ul">{`A Table cell should only contain one value.`}</li>
      <li parentName="ul">{`Tables must only be used for tabular data, not layout.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Column Comparison`}</h3>
    <p>{`Column Comparison is the default Table.
It is useful for comparing columns of data.`}</p>
    <ComponentPreview componentName="table--column-comparison" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Column Comparison Table Example" mdxType="ComponentPreview" />
    <h3>{`Secondary Table`}</h3>
    <p>{`Secondary Table reduces the visual weight of
the headers, making the Table stand out less.`}</p>
    <ComponentPreview componentName="table--secondary" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Secondary Table Example" mdxType="ComponentPreview" />
    <h3>{`Grouped Columns`}</h3>
    <p>{`Grouped Column Table should be used when multiple columns
have a similar theme and need to be grouped with a higher
level header.`}</p>
    <ComponentPreview componentName="table--grouped-columns" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Grouped Columns Table Example" mdxType="ComponentPreview" />
    <h3>{`Row Comparison`}</h3>
    <p>{`Row Comparison adds a header to each row,
allowing data to be grouped across rows and columns.`}</p>
    <ComponentPreview componentName="table--row-comparison" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Row Comparison Table Example" mdxType="ComponentPreview" />
    <h3>{`Secondary Row Comparison`}</h3>
    <p>{`Secondary Row Comparison removes the column
headers and adds a dedicated column for an action.`}</p>
    <ComponentPreview componentName="table--secondary-row-comparison" hasReact hasAngular hasHTML maxWidth="100%" titleAttr="Secondary Row Comparison Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Table must include headers (column, row, or both).`}</li>
      <li parentName="ul">{`Secondary Row Comparison Table should include an action,
such as a `}<a parentName="li" {...{
          "href": "/using-spark/components/link"
        }}>{`Link`}</a>{` or
`}<a parentName="li" {...{
          "href": "/using-spark/components/button"
        }}>{`Button`}</a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      